import Image1 from './assets/img/image 19.png'
import Image2 from './assets/img/image 18.png'
import Image3 from './assets/img/image 21.png'
import Image4 from './assets/img/image 22.png'
import { Card, Button } from 'flowbite-react'

function Services() {
    return (
        <div className="p-4 px-4 mx-auto md:px-8">
            <div className="mb-10 md:mb-16">
                <div className="m-4">
                    <span className="text-red-600 text-base me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-600">
                        Company Expertise
                    </span>
                </div>
                <h2 className="mb-4 text-6xl font-bold text-center text-gray-800 lg:text-3xl md:mb-6">
                    Our Services
                </h2>
                <p className="max-w-screen-lg mx-auto text-center text-black md:text-lg">
                    When you work with us, we will put ourselves into your systems, making sure we understand the way you work, thus helping us gain a stronger and more strategic partnership. Whatever your ICT requirements may be, IndoBSD will have the answer to your inquiries.
                </p>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                <Card
                    className="max-w-sm shadow-lg"
                    imgAlt="ICT CONSULTING"
                    imgSrc={Image1}
                >
                    <h5 className="text-xl text-left font-bold tracking-tight text-gray-900 dark:text-white">
                        ICT CONSULTING
                    </h5>
                    <p className="text-sm text-left text-gray-700 dark:text-gray-400">
                        ICT firm supports project, ensures customer satisfaction.
                    </p>
                </Card>
                <Card
                    className="max-w-sm shadow-lg"
                    imgAlt="ICT STRATEGY"
                    imgSrc={Image2}
                >
                    <h5 className="text-xl text-left font-bold tracking-tight text-gray-900 dark:text-white">
                        ICT STRATEGY
                    </h5>
                    <p className="text-sm text-left text-gray-700 dark:text-gray-400">
                        ICT firm supports project, ensures customer satisfaction.
                    </p>
                </Card>
                <Card
                    className="max-w-sm shadow-lg"
                    imgAlt="ICT PERFORMANCE IMPROVEMENT"
                    imgSrc={Image3}
                >
                    <h5 className="text-xl text-left font-bold tracking-tight text-gray-900 dark:text-white">
                        ICT PERFORMANCE IMPROVEMENT
                    </h5>
                    <p className="text-sm text-left text-gray-700 dark:text-gray-400">
                        ICT firm supports project, ensures customer satisfaction.
                    </p>
                </Card>
                <Card
                    className="max-w-sm shadow-lg"
                    imgAlt="ICT INFRASTRUCTURE SOLUTION"
                    imgSrc={Image4}
                >
                    <h5 className="text-xl text-left font-bold tracking-tight text-gray-900 dark:text-white">
                        ICT INFRASTRUCTURE SOLUTION
                    </h5>
                    <p className="text-sm text-left text-gray-700 dark:text-gray-400">
                        ICT firm supports project, ensures customer satisfaction.
                    </p>
                </Card>
            </div>
            <div className="flex mt-4 justify-center w-auto">
                <Button color="failure">More Services</Button>
            </div>
        </div>
    );
}
export default Services;
