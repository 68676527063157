import { Fragment, useState } from 'react'
import logo from './../logo.png';
import { Button } from 'flowbite-react';
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import {
    XMarkIcon, 
    Bars3Icon, 
    ArrowPathIcon,
    ChartPieIcon,
    CursorArrowRaysIcon,
    FingerPrintIcon,
    SquaresPlusIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'
import './NavHea.css'

const products = [
    { name: 'Analytics', description: 'Get a better understanding of your traffic', href: '#', icon: ChartPieIcon },
    { name: 'Engagement', description: 'Speak directly to your customers', href: '#', icon: CursorArrowRaysIcon },
    { name: 'Security', description: 'Your customers’ data will be safe and secure', href: '#', icon: FingerPrintIcon },
    { name: 'Integrations', description: 'Connect with third-party tools', href: '#', icon: SquaresPlusIcon },
    { name: 'Automations', description: 'Build strategic funnels that will convert', href: '#', icon: ArrowPathIcon },
]

const information = [
    { name: 'Blog', description: 'Get a better understanding of your traffic', href: '/blog', icon: ChartPieIcon },
    { name: 'Event', description: 'Speak directly to your customers', href: '/event', icon: CursorArrowRaysIcon },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function NavHea() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    return (
        <div>
            {/* <Navbar className="bg-[#F2F2F2] h-11 dark:bg-gray-700">
                <div className="absolute inline pt-5 pr-5 right-0 space-x-6 rtl:space-x-reverse">
                    <a href="/maintenance" className="text-base xs:text-xs sm:text-sm text-black dark:text-white hover:underline">Location</a>
                    <a href="/maintenance" className="text-base xs:text-xs sm:text-sm text-black dark:text-white hover:underline">Career</a>
                    <a href="/maintenance" className="text-base xs:text-xs sm:text-sm text-black dark:text-white hover:underline">Disclamer</a>
                    <a href="/maintenance" className="text-base xs:text-xs sm:text-sm text-black dark:text-white hover:underline">Sitemap</a>
                    <a href="/maintenance" className="text-base xs:text-xs sm:text-sm text-black dark:text-white hover:underline">Cookie</a>
                    <a href="/maintenance" className="text-base xs:text-xs sm:text-sm text-black dark:text-white hover:underline">Language</a>
                </div>
            </Navbar> */}
            <div className="overflow-auto">
                <ul className="flex w-auto block bg-[#F2F2F2] h-11 dark:bg-gray-700 right-0 items-center nav-link">
                    <li className="mr-6">
                        <a href="/maintenance" className="text-base xs:text-xs sm:text-sm text-black dark:text-white hover:underline">Location</a>
                    </li>
                    <li className="mr-6">
                        <a href="/maintenance" className="text-base xs:text-xs sm:text-sm text-black dark:text-white hover:underline">Career</a>
                    </li>
                    <li className="mr-6">
                        <a href="/maintenance" className="text-base xs:text-xs sm:text-sm text-black dark:text-white hover:underline">Disclamer</a>
                    </li>
                    <li className="mr-6">
                        <a href="/maintenance" className="text-base xs:text-xs sm:text-sm text-black dark:text-white hover:underline">Sitemap</a>
                    </li>
                    <li className="mr-6">
                        <a href="/maintenance" className="text-base xs:text-xs sm:text-sm text-black dark:text-white hover:underline">Cookie</a>
                    </li>
                    <li className="mr-6">
                        <a href="/maintenance" className="text-base xs:text-xs sm:text-sm text-black dark:text-white hover:underline">Language</a>
                    </li>
                </ul>
            </div>
            <header className="bg-white shadow-sm">
                <nav className="mx-auto flex max-w-7xl items-center justify-between p-3 lg:px-4" aria-label="Global">
                    <div className="flex xl:-pr-1 2xl:-pr-2 lg:pr-6">
                        <Link to="/" className="-m-1.5 p-1.5">
                            <img className="h-11 w-auto" src={logo} alt="IndoBSD Logo" />
                        </Link>
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <Popover.Group className="hidden lg:flex lg:gap-x-12">
                        <Link to="/home" className="text-base leading-6 text-black hover:text-red-600 active:text-red-600">
                            Home
                        </Link>
                        <Link to="/who_we_are" className="navlink text-base leading-6 text-black hover:text-red-600 active:text-red-600">
                            Who We Are
                        </Link>
                        <Link to="/vision_mission" className="text-base leading-6 text-black hover:text-red-600 active:text-red-600">
                            Vision & Mission
                        </Link>
                        <Popover className="relative text-base text-black hover:text-red-600">
                            <Popover.Button className="flex items-center gap-x-1 leading-6">
                                Services
                                <ChevronDownIcon className="h-5 w-5 flex-none" aria-hidden="true" />
                            </Popover.Button>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                            >
                                <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                                    <div className="p-4">
                                        {products.map((item) => (
                                            <div
                                                key={item.name}
                                                className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                                            >
                                                <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                                    <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                                                </div>
                                                <div className="flex-auto">
                                                    <a href={item.href} className="block font-semibold text-gray-900">
                                                        {item.name}
                                                        <span className="absolute inset-0" />
                                                    </a>
                                                    <p className="mt-1 text-gray-600">{item.description}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </Popover.Panel>
                            </Transition>
                        </Popover>
                        <Popover className="relative text-base text-black hover:text-red-600">
                            <Popover.Button className="flex items-center gap-x-1 leading-6">
                                Informations
                                <ChevronDownIcon className="h-5 w-5 flex-none" aria-hidden="true" />
                            </Popover.Button>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                            >
                                <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                                    <div className="p-4">
                                        {information.map((item) => (
                                            <div
                                                key={item.name}
                                                className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                                            >
                                                <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                                    <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                                                </div>
                                                <div className="flex-auto">
                                                    <a href={item.href} className="block font-semibold text-gray-900">
                                                        {item.name}
                                                        <span className="absolute inset-0" />
                                                    </a>
                                                    <p className="mt-1 text-gray-600">{item.description}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </Popover.Panel>
                            </Transition>
                        </Popover>
                    </Popover.Group>
                    <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                        <Button href="#" color="failure">Contact Us</Button>
                    </div>
                </nav>
                <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                    <div className="fixed inset-0 z-10" />
                    <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-between">
                            <Link to="/" className="-m-1.5 p-1.5">
                                <img
                                    className="h-8 w-auto"
                                    src={logo}
                                    alt=""
                                />
                            </Link>
                            <button
                                type="button"
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    <Link to="/home" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >Home
                                    </Link>
                                    <Link to="/who_we_are" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >
                                        Who We Are
                                    </Link>
                                    <Link to="/vision_mission" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >
                                        Vision & Mission
                                    </Link>
                                    <Disclosure as="div" className="-mx-3">
                                        {({ open }) => (
                                            <>
                                                <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                                    Services
                                                    <ChevronDownIcon
                                                        className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                                                        aria-hidden="true"
                                                    />
                                                </Disclosure.Button>
                                                <Disclosure.Panel className="mt-2 space-y-2">
                                                    {[...products].map((item) => (
                                                        <Disclosure.Button
                                                            key={item.name}
                                                            as="a"
                                                            href={item.href}
                                                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                                        >
                                                            {item.name}
                                                        </Disclosure.Button>
                                                    ))}
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                    <Disclosure as="div" className="-mx-3">
                                        {({ open }) => (
                                            <>
                                                <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                                    Informations
                                                    <ChevronDownIcon
                                                        className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                                                        aria-hidden="true"
                                                    />
                                                </Disclosure.Button>
                                                <Disclosure.Panel className="mt-2 space-y-2">
                                                    {[...information].map((item) => (
                                                        <Disclosure.Button
                                                            key={item.name}
                                                            as="a"
                                                            href={item.href}
                                                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                                        >
                                                            {item.name}
                                                        </Disclosure.Button>
                                                    ))}
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                </div>
                                <div className="py-6">
                                    <Link to="#" className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >
                                        Contact Us
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Dialog.Panel>
                </Dialog>
            </header>
        </div>
    );
}
export default NavHea;
