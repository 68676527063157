// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-link {
    justify-content: end;
}

@media only screen and (max-width: 600px) {
    .nav-link {
        justify-content: unset;
        overflow-x: auto;
        padding-left: 24px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/NavHea.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;AACxB;;AAEA;IACI;QACI,sBAAsB;QACtB,gBAAgB;QAChB,kBAAkB;IACtB;AACJ","sourcesContent":[".nav-link {\n    justify-content: end;\n}\n\n@media only screen and (max-width: 600px) {\n    .nav-link {\n        justify-content: unset;\n        overflow-x: auto;\n        padding-left: 24px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
