// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/img/commitment.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/img/integrity.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/img/solution.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.commitment {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-size: auto 100%;
}

.integrity {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

.solutions {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

@media only screen and (max-width: 600px) {
    .tab-wwa {
        margin-left: 0px;
    }

    .about-us {
        height: 860px;
    }

    .our-commitment {
        height: 1350px;
    }

    .our-clients {
        height: 1800px;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/WhoWeAre.css"],"names":[],"mappings":"AAAA;IACI,yDAAoD;IACpD,4BAA4B;IAC5B,0BAA0B;AAC9B;;AAEA;IACI,yDAAmD;AACvD;;AAEA;IACI,yDAAkD;AACtD;;AAEA;IACI;QACI,gBAAgB;IACpB;;IAEA;QACI,aAAa;IACjB;;IAEA;QACI,cAAc;IAClB;;IAEA;QACI,cAAc;IAClB;AACJ","sourcesContent":[".commitment {\n    background-image: url(\"./assets/img/commitment.png\");\n    background-repeat: no-repeat;\n    background-size: auto 100%;\n}\n\n.integrity {\n    background-image: url(\"./assets/img/integrity.png\");\n}\n\n.solutions {\n    background-image: url(\"./assets/img/solution.png\");\n}\n\n@media only screen and (max-width: 600px) {\n    .tab-wwa {\n        margin-left: 0px;\n    }\n\n    .about-us {\n        height: 860px;\n    }\n\n    .our-commitment {\n        height: 1350px;\n    }\n\n    .our-clients {\n        height: 1800px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
