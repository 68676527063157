import { Outlet } from "react-router-dom"
import Header from "../components/NavHea"
import Footer from "../components/NavFoo"

export default function Layout() {
    return (
        <>
            <Header />
            <main>
                <Outlet />
            </main>
            <Footer />
        </>
    )
}