// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/img/Vision & Mission.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vm {
    background-image: linear-gradient(180deg, rgba(29, 29, 29, 1) -23.45%, rgba(88, 88, 88, 0)), url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: auto;
}

@media only screen and (max-width: 600px) {
    .text-vm {
        font-size: xx-large;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/VisionMission.css"],"names":[],"mappings":"AAAA;IACI,oIAAuI;IACvI,aAA+C;IAA/C,sBAA+C;IAA/C,2BAA+C;IAA/C,4BAA+C;IAC/C,YAAY;AAChB;;AAEA;IACI;QACI,mBAAmB;IACvB;AACJ","sourcesContent":[".vm {\n    background-image: linear-gradient(180deg, rgba(29, 29, 29, 1) -23.45%, rgba(88, 88, 88, 0)), url(\"./assets/img/Vision\\ &\\ Mission.png\");\n    @apply h-screen bg-cover bg-center bg-no-repeat;\n    height: auto;\n}\n\n@media only screen and (max-width: 600px) {\n    .text-vm {\n        font-size: xx-large;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
