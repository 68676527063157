// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/img/background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home {
    background-image: linear-gradient(180deg, rgba(29, 29, 29, 1) -23.45%, rgba(88, 88, 88, 0)), url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.title-home {
    font-size: 72px;
}

@media only screen and (max-width: 600px) {
    .title-home {
        font-size: 40px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .text-home {
        font-size: 20px;
        padding-left: 5px;
        padding-right: 5px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home.css"],"names":[],"mappings":"AAAA;IACI,oIAA+H;IAC/H,aAA+C;IAA/C,sBAA+C;IAA/C,2BAA+C;IAA/C,4BAA+C;AACnD;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI;QACI,eAAe;QACf,iBAAiB;QACjB,kBAAkB;IACtB;;IAEA;QACI,eAAe;QACf,iBAAiB;QACjB,kBAAkB;IACtB;AACJ","sourcesContent":[".home {\n    background-image: linear-gradient(180deg, rgba(29, 29, 29, 1) -23.45%, rgba(88, 88, 88, 0)), url('./assets/img/background.png');\n    @apply h-screen bg-cover bg-center bg-no-repeat;\n}\n\n.title-home {\n    font-size: 72px;\n}\n\n@media only screen and (max-width: 600px) {\n    .title-home {\n        font-size: 40px;\n        padding-left: 5px;\n        padding-right: 5px;\n    }\n\n    .text-home {\n        font-size: 20px;\n        padding-left: 5px;\n        padding-right: 5px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
