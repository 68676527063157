// import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
import Layout from "./pages/Layout"
import Error404 from './pages/Error_404'
import Maintenance from './pages/Maintenance';
import Services from './pages/Services';
import VisionMission from './pages/VisionMission';
import WhoWeAre from './pages/WhoWeAre';
import Home from './pages/Home';
import Blog from './pages/Blog';
import Event from './pages/Event';

function App() {
  const router = createBrowserRouter([
    {
      // parent route component
      element: <Layout />,
      // child route components
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/home",
          element: <Home />,
        },
        {
          path: "/blog",
          element: <Blog />,
        },
        {
          path: "/who_we_are",
          element: <WhoWeAre />,
        },
        {
          path: "/services",
          element: <Services />,
        },
        {
          path: "/event",
          element: <Event />,
        },
        {
          path: "/vision_mission",
          element: <VisionMission />,
        },
        {
          path: "/error_404",
          element: <Error404 />,
        },
        {
          path: "/maintenance",
          element: <Maintenance />,
        },
      ],
    },
  ])

return (
  <RouterProvider router={router} />
)
}

export default App;
