import { Footer } from 'flowbite-react'
import logo from '../logo.png'

function NavFoo() {
    return (
        <Footer container>
            <div className="w-full text-center">
                <span className="flex text-left text-6xl">Lets</span>
                <span className="flex text-left text-6xl text-[#DB4C35]">Collaborate</span>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6 w-auto mt-12 mb-12">
                    <div className="flex items-center p-4 border border-slate-300">
                        <div className="flex-grow flex flex-col ml-4 text-red-600 ">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                            </svg>

                            <div className="flex items-center justify-between">
                                <span className="text-black text-3xl mt-4">Book A Call</span>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center p-4 border border-slate-300">
                        <div className="flex-grow flex flex-col ml-4 text-red-600">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                            </svg>
                            <div className="flex items-center justify-between">
                                <span className="text-black text-3xl mt-4">Email Us</span>

                            </div>
                        </div>
                    </div>

                    <div className="flex items-center p-4 border border-slate-300">
                        <div className="flex-grow flex flex-col ml-4 text-red-600">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z" />
                            </svg>

                            <div className="flex items-center justify-between">
                                <span className="text-black text-3xl mt-4">Message Us</span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="flex grid w-full justify-between sm:flex sm:justify-between md:flex md:grid-cols-1">
                    <div>
                        <Footer.Brand
                            href="/"
                            src={logo}
                            alt="IndoBSD Logo"
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-8 sm:mt-4 sm:grid-cols-3 sm:gap-6">
                        <Footer.LinkGroup col>
                            <Footer.Link href="/home" className="text-black hover:text-red-600 active:text-red-600">Home</Footer.Link>
                            <Footer.Link href="/who_we_are" className="text-black hover:text-red-600 active:text-red-600">Who We Are</Footer.Link>
                            <Footer.Link href="/vision_mission" className="text-black hover:text-red-600 active:text-red-600">Vision & Mission</Footer.Link>
                            <Footer.Link href="/services" className="text-black hover:text-red-600 active:text-red-600">Services</Footer.Link>
                        </Footer.LinkGroup>
                        <div>
                            <Footer.Title title="Services" className="text-black" />
                            <Footer.LinkGroup col>
                                <Footer.Link href="/services" className="text-black hover:text-red-600 active:text-red-600">ICT</Footer.Link>
                                <Footer.Link href="/services" className="text-black hover:text-red-600 active:text-red-600">Security Operation Center</Footer.Link>
                            </Footer.LinkGroup>
                        </div>
                        <div>
                            <Footer.Title title="Informations" className="text-black" />
                            <Footer.LinkGroup col>
                                <Footer.Link href="/blog" className="text-black hover:text-red-600 active:text-red-600">Blog</Footer.Link>
                                <Footer.Link href="/event" className="text-black hover:text-red-600 active:text-red-600">Event</Footer.Link>
                            </Footer.LinkGroup>
                        </div>
                    </div>
                </div> */}
                <div className="flex items-center">
                    <Footer.Brand
                        href="/"
                        src={logo}
                        alt="IndoBSD Logo"
                    />
                    <Footer.LinkGroup className="flex w-full justify-center">
                        <div className="gap-6 sm:mt-4 sm:ml-24 sm:gap-2 sm:flex">
                            <Footer.Link href="/home" className="text-black hover:text-red-600 active:text-red-600">Home</Footer.Link>
                            <Footer.Link href="/who_we_are" className="text-black hover:text-red-600 active:text-red-600">Who We Are</Footer.Link>
                            <Footer.Link href="/vision_mission" className="text-black hover:text-red-600 active:text-red-600">Vision & Mission</Footer.Link>
                            <Footer.Link href="/services" className="text-black hover:text-red-600 active:text-red-600">Services</Footer.Link>
                        </div>
                    </Footer.LinkGroup>
                </div>
                <Footer.Divider />
                <div className="w-full justify-between items-center sm:flex sm:items-center sm:justify-between">
                    <span className="text-base text-left">2023 IndoBSD. All right reserved</span>
                    <Footer.LinkGroup>
                        <div className="gap-6 sm:mt-4 sm:gap-2 sm:flex w-full">
                            <Footer.Link href="/maintenance">Privacy Policy</Footer.Link>
                            <Footer.Link href="/maintenance">Terms of Service</Footer.Link>
                            <Footer.Link href="/maintenance">Cookies</Footer.Link>
                        </div>
                    </Footer.LinkGroup>
                </div>
            </div>
        </Footer>
    );
}
export default NavFoo;
