import { IoEyeOutline, IoLocateOutline } from "react-icons/io5";
import './VisionMission.css'

function VisionMission() {
    return (
        <div className="p-4 px-4 mx-auto w-auto md:px-8 vm">
            <div className="mb-10 md:mb-16">
                <div className="mb-6 mt-6 text-left">
                    <span className="text-white text-base p-2 rounded dark:text-white border border-white">Our Values</span>
                </div>
                <h2 className="mb-4 text-7xl font-bold text-left text-vm text-white sm:text-2xl lg:text-3xl md:mb-6">
                    We are extremely committed to delivering quality advice, sales, and support to assist our clients in achieving their maximum potential from ICT investment.
                </h2>
                <p className="text-left text-white sm:max-w-5xl md:text-lg">
                    When you work with us, we will put ourselves into your systems, making sure we understand the way you work, thus helping us gain a stronger and more strategic partnership. Whatever your ICT requirements may be, IndoBSD will have the answer to your inquiries.
                </p>
            </div>
            <div className="flex flex-row py-10">
                <div className="grid text-white grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
                    <div>
                        <div className="grid grid-flow-col gap-4">
                            <div className="mt-1.5">
                                <IoEyeOutline className="w-5 h-5 border rounded border-white" />
                            </div>
                            <div className="col-span-2">
                                <h1 className="text-2xl pb-2 font-bold text-left">Vision</h1>
                                <hr className="bg-gray-500" />
                                <p className="text-left pt-2">To be a leading digital transformation company, that fully leverages the changes and opportunities technology and improve business performances.</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="grid grid-flow-col gap-4">
                            <div className="mt-1.5">
                                <IoLocateOutline className="w-5 h-5 border rounded border-white" />
                            </div>
                            <div className="col-span-2">
                                <h1 className="text-2xl pb-2 font-bold text-left">Mission</h1>
                                <hr className="bg-gray-500" />
                                <p className="text-left pt-2">To provide strategic ICT and service operations, build and maintain the most innovative, reliable, and cost-effective ICT solutions in order to meet our customers needs through rapid deployment of new communication services and deliver long-term commercial benefits based on the key business requirement of our clients.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default VisionMission;